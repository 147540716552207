.animate-pulse-gradient {
  background: linear-gradient(to right, #e2e8f0 0%, #ffffff 50%, #e2e8f0 100%);
  background-size: 200% auto;
  animation: pulse-gradient 2s ease-in-out infinite;
}

@keyframes pulse-gradient {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
